.addlist h3 {
  padding: 10px 0;
}
.addlist .el-icon-plus:before {
  content: "\e6d9";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.zsBtn {
  margin-left: 20px;
}
.zsBtn button {
  height: 40px;
}
.certificate-box > div:last-child {
  border: 0;
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
.sabicss .el-checkbox__inner {
  min-width: 0;
  padding: 0;
}
